// used in /settings/products/new & /settings/products/:id/edit
if (document.getElementById('product-cover-preview')) {
  import(
    /* webpackChunkName: "settingsProductCoverPreview" */ './components/settingsProductCoverPreview'
  );
}

import ReactOnRails from 'react-on-rails';
import { lazyHydrate, lazyHydrateNearScreen, noHydrate } from './utils/hydrate';
// Above the fold contents should be statically included
import SearchBox from './components/searchBox/searchBox';

ReactOnRails.register({
  BrowserDetector: noHydrate,
  SearchBox,
  Brand: lazyHydrate(() =>
    import(/* webpackChunkName: "Brand" */ './components/Brand')
  ),
  Comments: lazyHydrateNearScreen(() =>
    import(/* webpackChunkName: "Comments" */ './components/NewComments')
  ),
  CommentThreadPage: lazyHydrate(() =>
    import(
      /* webpackChunkName: "CommentThreadPage" */ './components/NewComments/CommentThreadPage'
    )
  ),
  TrialFeedbackForm: lazyHydrate(() =>
    import(
      /* webpackChunkName: "TrialFeedbackForm" */ './components/TrialFeedbackForm'
    )
  ),
  Experience: lazyHydrate(() =>
    import(
      /* webpackChunkName: "Experience" */ './components/Experience/Experience'
    )
  ),
  SearchKeyword: lazyHydrate(() =>
    import(/* webpackChunkName: "SearchKeyword" */ './components/SearchKeyword')
  ),
  SearchResultSurvey: lazyHydrate(() =>
    import(
      /* webpackChunkName: "SearchResultSurvey" */ './components/SearchResultSurvey'
    )
  ),
  MessageBlock: lazyHydrate(() =>
    import(
      /* webpackChunkName: "MessageBlock" */ './components/SortableList/MessageBlock'
    )
  ),
  SortableList: lazyHydrate(() =>
    import(
      /* webpackChunkName: "SortableList" */ './components/SortableList/SortableList'
    )
  ),
  RecipesDishesModule: lazyHydrateNearScreen(() =>
    import(
      /* webpackChunkName: "RecipesDishesModule" */ './components/Dish/RecipesDishesModule/RecipesDishesModule'
    )
  ),
  RecipesDishes: lazyHydrate(() =>
    import(
      /* webpackChunkName: "RecipesDishes" */ './components/Dish/RecipesDishes/RecipesDishes'
    )
  ),
  DishesShow: lazyHydrate(() =>
    import(
      /* webpackChunkName: "DishesShow" */ './components/Dish/DishesShow/DishesShow'
    )
  ),
  DishesEditor: lazyHydrate(() =>
    import(
      /* webpackChunkName: "DishesEditor" */ './components/Dish/DishesEditor/DishesEditor'
    )
  ),
  DishesExplorePage: lazyHydrate(() =>
    import(
      /* webpackChunkName: "DishesExplorePage" */ './components/Dish/DishesExplorePage/DishesExplorePage'
    )
  ),
  RecipeInteractBtns: lazyHydrate(() =>
    import(
      /* webpackChunkName: "RecipeInteractBtns" */ './components/InteractButtons/RecipeInteractBtns'
    )
  ),
  RecipeFollowUserButton: lazyHydrate(() =>
    import(
      /* webpackChunkName: "RecipeFollowUserButton" */ './components/RecipeFollowUserButton'
    )
  ),
  RecipeLikeButton: lazyHydrate(() =>
    import(
      /* webpackChunkName: "RecipeLikeButton" */ './components/RecipeLikeButton'
    )
  ),
  RecipeSaveButton: lazyHydrate(() =>
    import(
      /* webpackChunkName: "RecipeSaveButton" */ './components/RecipeSaveButton/RecipeSaveButton'
    )
  ),
  RecipeNew: lazyHydrate(() =>
    import(
      /* webpackChunkName: "RecipeNew" */ './components/RecipeEditor/RecipeNew'
    )
  ),
  RecipeShowVideoState: lazyHydrate(() =>
    import(
      /* webpackChunkName: "RecipeShowVideoState" */ './components/RecipeEditor/RecipeShowVideoState'
    )
  ),
  SocialMediaShare: lazyHydrate(() =>
    import(
      /* webpackChunkName: "SocialMediaShare" */ './components/SocialMediaShare'
    )
  ),
  Tooltips: lazyHydrate(() =>
    import(/* webpackChunkName: "Tooltips" */ './components/Tooltips')
  ),
  Snackbar: lazyHydrate(() =>
    import(/* webpackChunkName: "Snackbar" */ './components/Snackbar')
  ),
  NoResultPlaceholder: lazyHydrate(() =>
    import(
      /* webpackChunkName: "NoResultPlaceholder" */ './components/NoResultPlaceholder'
    )
  ),
  VideoPlayer: lazyHydrate(() =>
    import(/* webpackChunkName: "VideoPlayer" */ './components/VideoPlayer')
  ),
  VIPLanding: lazyHydrate(() =>
    import(
      /* webpackChunkName: "VIPLanding" */ './components/VIP/VIPLanding/VIPLanding'
    )
  ),
  VIPLandingExpired: lazyHydrate(() =>
    import(
      /* webpackChunkName: "VIPLandingExpired" */ './components/VIP/VIPLanding/VIPLandingExpired'
    )
  ),
  VIPProfile: lazyHydrate(() =>
    import(
      /* webpackChunkName: "VIPProfile" */ './components/VIP/VIPProfile/VIPProfile'
    )
  ),
  VIPSuccess: lazyHydrate(() =>
    import(
      /* webpackChunkName: "VIPSuccess" */ './components/VIP/VIPSuccess/VIPSuccess'
    )
  ),
  VIPSubscribe: lazyHydrate(() =>
    import(
      /* webpackChunkName: "VIPSubscribe" */ './components/VIP/VIPSubscribe/VIPSubscribe'
    )
  ),
  VIPUnsubscribe: lazyHydrate(() =>
    import(
      /* webpackChunkName: "VIPUnsubscribe" */ './components/VIP/VIPUnsubscribe/VIPUnsubscribe'
    )
  ),
  VIPPortal: lazyHydrate(() =>
    import(
      /* webpackChunkName: "VIPPortal" */ './components/VIP/VIPPortal/VIPPortal'
    )
  ),
  VIPRedeem: lazyHydrate(() =>
    import(
      /* webpackChunkName: "VIPRedeem" */ './components/VIP/VIPRedeem/VIPRedeem'
    )
  ),
  UserPage: lazyHydrate(() =>
    import(/* webpackChunkName: "UserPage" */ './components/UserPage/UserPage')
  ),
  UserPageEvent: lazyHydrate(() =>
    import(
      /* webpackChunkName: "UserPageEvent" */ './components/UserPage/UserPageEvent'
    )
  ),
  UserPageCardSection: lazyHydrate(() =>
    import(
      /* webpackChunkName: "UserPageCardSection" */ './components/UserPage/UserPageCardSection'
    )
  ),
  UserPageHeader: lazyHydrate(() =>
    import(
      /* webpackChunkName: "UserPageHeader" */ './components/UserPage/UserPageHeader'
    )
  ),
  UserPageSettingHeader: lazyHydrate(() =>
    import(
      /* webpackChunkName: "UserPageSettingHeader" */ './components/UserPage/UserPageSettingHeader'
    )
  ),
  UserPageIntro: lazyHydrate(() =>
    import(
      /* webpackChunkName: "UserPageIntro" */ './components/UserPage/UserPageIntro'
    )
  ),
  UserPageEdit: lazyHydrate(() =>
    import(
      /* webpackChunkName: "UserPageEdit" */ './components/UserPage/UserPageEdit'
    )
  ),
  UserProfile: lazyHydrate(() =>
    import(/* webpackChunkName: "UserProfile" */ './components/UserProfile')
  ),
  UserSocialProfile: lazyHydrate(() =>
    import(
      /* webpackChunkName: "UserSocialProfile" */ './components/UserSocialProfile'
    )
  ),
  UserFollowList: lazyHydrate(() =>
    import(
      /* webpackChunkName: "UserFollowList" */ './components/UserFollowList'
    )
  ),
  UserFavorites: lazyHydrate(() =>
    import(
      /* webpackChunkName: "UserFavorites" */ './components/UserFavorites/UserFavorites'
    )
  ),
  UsersDishes: lazyHydrate(() =>
    import(
      /* webpackChunkName: "UsersDishes" */ './components/Dish/UsersDishes/UsersDishes'
    )
  ),
  SelectedAuthors: lazyHydrate(() =>
    import(
      /* webpackChunkName: "SelectedAuthors" */ './components/SelectedAuthors'
    )
  ),
  SearchFilterModal: lazyHydrate(() =>
    import(
      /* webpackChunkName: "SearchFilterModal" */ './components/SearchFilterModal'
    )
  ),
  PrWidgetDishCampaign: lazyHydrate(() =>
    import(
      /* webpackChunkName: "PrWidgetDishCampaign" */ './components/pr-widget/views/DishCampaign'
    )
  ),
  PrWidgetRecipeCampaign: lazyHydrate(() =>
    import(
      /* webpackChunkName: "PrWidgetRecipeCampaign" */ './components/pr-widget/views/RecipeCampaign'
    )
  )
});
